import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";

import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { Course, CourseRate } from "@kalabo/golfsavers-common";
import {
  GolfOrderLineCaddie,
  GolfOrderLineCart,
  GolfOrderLineGreenFee,
  GolfOrderLineTeeTime,
  GolfOrderLineTeeTime_Groups,
} from "@kalabo/golfsavers-common";
import { getGroupSizeBreakdown } from "@kalabo/golfsavers-common";
import _ from "lodash";
import { BookState } from "@models/book";

const getCaddiePolicy = (rate: any) => {
  if (!rate?.caddie_policy) return null;
  return rate.caddie_policy;
};

const getCartPolicy = (rate: any) => {
  if (!rate?.cart_policy) return null;
  return rate.cart_policy;
};

const getGroupSizes = (
  tee_time: GolfOrderLineTeeTime,
  number_of_golfers: number,
  max_pax: number
) => {
  let group_sizes: GolfOrderLineTeeTime_Groups[] = [];
  let new_breakdown = getGroupSizeBreakdown(number_of_golfers, max_pax);
  group_sizes = _.cloneDeep(tee_time?.groups || []);

  if (new_breakdown.length < group_sizes.length) {
    group_sizes = group_sizes.slice(0, new_breakdown.length);
    new_breakdown.forEach((group, index) => {
      group_sizes[index].number_of_players = group;
    });
  } else {
    new_breakdown.forEach((group, index) => {
      if (group_sizes[index]) {
        group_sizes[index].number_of_players = group;
      } else {
        group_sizes.push({
          number_of_players: group,
          start_time: "",
        });
      }
    });
  }

  // if the length of the group_sizes is greater than the length of the new_breakdown then remove the extra ones
  if (group_sizes.length > new_breakdown.length) {
    group_sizes = group_sizes.slice(0, new_breakdown.length);
  }

  return group_sizes;
};

const initialState: any = {
  rate: null,
  note: null,
  golf_line: {
    workflow_settings: {
      mode: "AUTO",
    },
    where_when_who: {
      region_id: null,
      hole_option: "ANY",
      date: dayjs().add(2, "day").format("YYYY-MM-DD"),
      number_of_golfers: 2,
    },
    course: {
      id: null,
      name: null,
      policies: null,
      closures: [],
      warnings: [],
      public_holidays: [],
      hole_options: [],
    },
    hole_options: {
      hole_options: [],
      selected_option: null,
    },
    supplier: {
      id: null,
      name: null,
      currency: null,
      offerings: null,
    },
    product: {
      id: null,
      name: null,
      type: null,
      config: null,
      custom: false,
    },
    green_fee: {
      vat: 0,
      withholding_tax: 0,
      rate_id: null,
      caddie_price: 0,
      single_cart_enabled: false,
      single_cart_price: 0,
      shared_cart_enabled: false,
      shared_cart_price: 0,
      odd_number_surcharge: 0,
      max_pax: 0,
      margin: 0,
      cost: 0,
      price: 0,
      caddie_policy: null,
      cart_policy: null,
      surcharge: 0,
      availability_days: null,
      earliest_time: null,
      latest_time: null,
      start_date: null,
      end_date: null,
      booking_window_start_date: null,
      booking_window_end_date: null,
    },
    tee_time: {
      note: null,
      custom: false,
      start_time: null,
      end_time: null,
      groups: [],
    },
    caddies: {
      enabled: false,
      vat: 0,
      withholding_tax: 0,
      type: null,
      cost_per_caddie: 0,
      quantity: 0,
      total: 0,
      payment_option: null,
    },
    carts: {
      enabled: false,
      vat: 0,
      withholding_tax: 0,
      types: null,
      payment_option: null,
    },
    golfer_info: {
      enabled: false,
      players: [],
    },
    voucher_notes: "",
    supplier_deposit: {
      enabled: false,
      amount: 0,
      type: "PERCENTAGE",
      days_before: 0,
    },
  },
  transfer_line: {
    workflow_settings: {
      mode: "AUTO",
    },
    where_when_who: {
      region_id: null,
      number_of_passengers: 1,
      type: "RETURN",
      date: dayjs().add(3, "day").format("YYYY-MM-dd"),
    },
    supplier: {
      id: null,
      name: null,
      currency: null,
      notes: null,
    },
    driver: {
      pickup_time: null,
      name: null,
      phone: null,
    },
    transfer_legs: {
      outbound: {
        from_area: null,
        from_name: null,
        from_type: null,
        from_details: null,
        to_area: null,
        to_name: null,
        to_type: null,
        to_details: {
          requested_arrival_time: 30,
        },
      },
      inbound: {
        from_area: null,
        from_name: null,
        from_type: null,
        from_details: {
          requested_arrival_time: 30,
        },
        to_area: null,
        to_name: null,
        to_type: null,
        to_details: null,
      },
    },
    vehicle: {
      vehicle: null,
      rate: null,
      max_pax: 0,
      cost: 0,
      margin: 0,
      price: 0,
      quantity: 0,
      total: 0,
      vat: 0,
      withholding_tax: 0,
    },
    voucher_notes: "",
    supplier_deposit: {
      enabled: false,
      amount: 0,
      type: "PERCENTAGE",
      days_before: null,
    },
  },
  order_id: null,
  step: "tee_time",
};

export const bookStore = () =>
  create<BookState>()(
    persist(
      immer((set, get) => ({
        ...initialState,
        order_id: null,
        step: "tee_time",
        deinit: () => {
          console.log("deinit");
          set((state) => {});
        },
        setStep: (step: string) => {
          set((state) => {
            state.step = step;
          });
        },
        setNote: (note: string) => {
          set((state) => {
            state.note = note;
          });
        },
        setRate: (rate: CourseRate) => {
          set((state) => {
            state.rate = rate;
          });
        },
        clearRate: () => {
          set((state) => {
            state.rate = null;
          });
        },
        setTransferLegs: (legs: any) => {
          set((state) => {
            let transfer_obj = _.cloneDeep(state.transfer_line);
            transfer_obj.transfer_legs = _.merge(
              transfer_obj.transfer_legs,
              legs
            );

            state.transfer_line = transfer_obj;
          });
        },
        setTransferSupplier: (supplier: any) => {
          set((state) => {
            let transfer_obj = _.cloneDeep(state.transfer_line);
            transfer_obj.supplier = _.merge(transfer_obj.supplier, supplier);

            state.transfer_line = transfer_obj;
          });
        },
        setTransferVehicle: (vehicle: any) => {
          set((state) => {
            let transfer_obj = _.cloneDeep(state.transfer_line);
            transfer_obj.vehicle = _.merge(transfer_obj.vehicle, vehicle);

            state.transfer_line = transfer_obj;
          });
        },
        setGreenFee: (greenFee: any) => {
          set((state) => {
            let golf_obj = _.cloneDeep(state.golf_line);
            golf_obj.green_fee = _.merge(golf_obj.green_fee, greenFee);

            state.golf_line = golf_obj;
          });
        },
        setCourse: (course: any) => {
          set((state) => {
            let golf_obj = _.cloneDeep(state.golf_line);
            golf_obj.course = _.merge(golf_obj.course, course);

            state.golf_line = golf_obj;
          });
        },
        setHoleOptions: (holeOptions: any) => {
          set((state) => {
            let golf_obj = _.cloneDeep(state.golf_line);
            golf_obj.hole_options = _.merge(golf_obj.hole_options, holeOptions);

            state.golf_line = golf_obj;
          });
        },
        setWhereWhenWho: (whereWhenWho: any) => {
          set((state) => {
            let golf_obj = _.cloneDeep(state.golf_line);
            golf_obj.where_when_who = _.merge(
              golf_obj.where_when_who,
              whereWhenWho
            );

            state.golf_line = golf_obj;
          });
        },
        setTransferWhereWhenWho: (whereWhenWho: any) => {
          set((state) => {
            let transfer_obj = _.cloneDeep(state.transfer_line);
            transfer_obj.where_when_who = _.merge(
              transfer_obj.where_when_who,
              whereWhenWho
            );

            state.transfer_line = transfer_obj;
          });
        },
        setCaddies: (caddies: any) => {
          set((state) => {
            let golf_obj = _.cloneDeep(state.golf_line);
            golf_obj.caddies = _.merge(golf_obj.caddies, caddies);

            state.golf_line = golf_obj;
          });
        },
        setCarts: (carts: any) => {
          set((state) => {
            let golf_obj = _.cloneDeep(state.golf_line);
            golf_obj.carts = _.merge(golf_obj.carts, carts);

            state.golf_line = golf_obj;
          });
        },
        setTeeTime: (teeTime: any) => {
          set((state) => {
            let golf_obj = _.cloneDeep(state.golf_line);
            golf_obj.tee_time = _.merge(golf_obj.tee_time, teeTime);

            state.golf_line = golf_obj;
          });
        },
        resetRate: () => {
          set((state) => {
            state.step = initialState.step;
            state.golf_line = initialState.golf_line;
          });
        },
        resetCaddieLine: () => {
          set((state) => {
            let golf_line_obj = _.cloneDeep(state.golf_line);

            let caddie_obj = _.cloneDeep(state.golf_line.caddies);
            caddie_obj.enabled = false;
            caddie_obj.payment_option = null;

            golf_line_obj.caddies = caddie_obj;
            state.golf_line = golf_line_obj;
          });
        },
        resetCartLine: () => {
          set((state) => {
            let golf_line_obj = _.cloneDeep(state.golf_line);

            let cart_obj = _.cloneDeep(state.golf_line.carts);

            cart_obj.enabled = false;
            cart_obj.types.forEach((type) => {
              type.quantity = 0;
            });
            cart_obj.payment_option = null;

            golf_line_obj.carts = cart_obj;

            state.golf_line = golf_line_obj;
          });
        },
        resetTransferVehicle: () => {
          set((state) => {
            state.transfer_line = {
              ...state.transfer_line,
              supplier: {
                ...initialState.transfer_line.supplier,
              },
              vehicle: {
                ...initialState.transfer_line.vehicle,
              },
            };
          });
        },
        resetTransferLine: () => {
          set((state) => {
            state.transfer_line = {
              ...initialState.transfer_line,
              where_when_who: {
                ...state.transfer_line.where_when_who,
              },
            };
          });
        },
        setGolferInfo: (golferInfo: any) => {
          set((state) => {
            let golf_obj = _.cloneDeep(state.golf_line);
            golf_obj.golfer_info = _.merge(golf_obj.golfer_info, golferInfo);

            state.golf_line = golf_obj;
          });
        },
        saveRateValues: (payload: any) => {
          set((state) => {
            let rate = payload.rate as CourseRate;
            let course = payload.course as Course;
            // set the rate
            state.rate = rate;
            // create a new guid for the order id
            state.order_id = uuidv4();

            let group_sizes: GolfOrderLineTeeTime_Groups[] = [];

            state.note = "";
            let transfer_line_obj = _.cloneDeep(state.transfer_line);
            transfer_line_obj.transfer_legs =
              initialState.transfer_line.transfer_legs;

            let golf_line_obj = _.cloneDeep(state.golf_line);

            transfer_line_obj.where_when_who.type = "RETURN";
            transfer_line_obj.where_when_who.number_of_passengers =
              state.golf_line.where_when_who?.number_of_golfers;

            transfer_line_obj.vehicle = initialState.transfer_line.vehicle;

            let featured_image = course?.images;
            golf_line_obj.course = {
              id: course.course_id,
              slug: course.slug,
              name: course.nickname,
              featured_image:
                featured_image?.length > 0 ? featured_image[0].url : "",
              region: course.region.name.en,
              country: course.region.country.name.en,
              closures: course.course_closures,
              warnings: course.course_warnings,
              policies: course.policies,
            };

            // if max_pax is set then update the group sizes
            if (rate.max_pax) {
              group_sizes = getGroupSizes(
                state.golf_line.tee_time,
                state.golf_line.where_when_who.number_of_golfers,
                rate.max_pax
              );
            }

            golf_line_obj.tee_time = _.merge(golf_line_obj.tee_time, {
              groups: group_sizes,
              start_time: rate.earliest_time.slice(0, 5),
              end_time: rate.latest_time.slice(0, 5),
            });

            let caddie_obj: GolfOrderLineCaddie = {
              vat: rate?.supplier_offerings?.golf?.offerings?.caddies?.vat || 0,
              withholding_tax:
                rate?.supplier_offerings?.golf?.offerings?.caddies
                  ?.withholding_tax || 0,
            };

            // Need to define the caddies
            if (course?.policies?.caddies?.type === "SINGLE_CADDIES") {
              golf_line_obj.caddies = {
                ...caddie_obj,
                enabled:
                  rate?.product_config?.caddie ||
                  rate?.product_config?.shared_caddie
                    ? false
                    : getCaddiePolicy(rate) === "compulsory" || null,
                type: "SINGLE_CADDIE",
                quantity: state?.golf_line?.where_when_who?.number_of_golfers,
                cost_per_caddie: Number(rate?.caddie_price),
                total:
                  rate?.caddie_price *
                  state.golf_line.where_when_who?.number_of_golfers,
                payment_option: !course.policies?.caddies?.prepayment_available
                  ? "ON_ARRIVAL"
                  : null,
              } as GolfOrderLineCaddie;
            }
            if (course?.policies?.caddies?.type === "SHARED_CADDIES") {
              golf_line_obj.caddies = {
                ...caddie_obj,
                enabled:
                  rate?.product_config?.caddie ||
                  rate?.product_config?.shared_caddie
                    ? false
                    : getCaddiePolicy(rate) === "compulsory" || null,
                type: "SHARED_CADDIE",
                quantity: 0,
                cost_per_caddie: Number(rate?.caddie_price),
                total: 0,
                payment_option: !course.policies?.caddies?.prepayment_available
                  ? "ON_ARRIVAL"
                  : null,
              } as GolfOrderLineCaddie;
            }

            let cart_obj: GolfOrderLineCart = {
              vat: rate?.supplier_offerings?.golf?.offerings?.carts?.vat || 0,
              withholding_tax:
                rate?.supplier_offerings?.golf?.offerings?.carts
                  ?.withholding_tax || 0,
              enabled:
                rate?.product_config?.shared_cart ||
                rate?.product_config?.single_cart
                  ? false
                  : getCartPolicy(rate) === "compulsory" || false,
              payment_option: !course.policies?.carts?.prepayment_available
                ? "ON_ARRIVAL"
                : null,
            };

            // If both cart types are available then we need to set values to 0 to let them decide
            if (rate?.shared_cart_enabled && rate?.single_cart_enabled) {
              // if the number of players is odd
              if (state.golf_line.where_when_who.number_of_golfers % 2 !== 0) {
                cart_obj = {
                  ...cart_obj,
                  enabled: getCartPolicy(rate) === "compulsory" || false,
                  types: [
                    {
                      cost_per_cart: Number(rate?.single_cart_price),
                      total:
                        rate?.single_cart_price *
                        state.golf_line.where_when_who.number_of_golfers,
                      quantity:
                        state.golf_line.where_when_who.number_of_golfers,
                      type: "SINGLE_CART",
                    },
                  ],
                };
              } else {
                cart_obj = {
                  ...cart_obj,
                  types: [
                    {
                      cost_per_cart: Number(rate?.single_cart_price),
                      total: 0,
                      quantity: 0,
                      type: "SINGLE_CART",
                    },
                    {
                      cost_per_cart: Number(rate?.shared_cart_price),
                      total: 0,
                      quantity: 0,
                      type: "SHARED_CART",
                    },
                  ],
                };
              }
            }
            // If its Shared Only Carts in the policy
            if (rate?.shared_cart_enabled && !rate?.single_cart_enabled) {
              // the quantity is the number of players divided by 2 rounded up to the nearest whole number
              let quantity = Math.ceil(
                state.golf_line.where_when_who?.number_of_golfers / 2
              );

              cart_obj = {
                ...cart_obj,
                types: [
                  {
                    cost_per_cart: rate?.shared_cart_price,
                    total: rate?.shared_cart_price * quantity,
                    quantity: quantity,
                    type: "SHARED_CART",
                  },
                ],
              };
            }

            // If its Single Only Carts in the policy
            if (!rate?.shared_cart_enabled && rate?.single_cart_enabled) {
              // the quantity is the number of players divided by 2 rounded up to the nearest whole number
              let quantity = state.golf_line.where_when_who?.number_of_golfers;

              cart_obj = {
                ...cart_obj,
                types: [
                  {
                    cost_per_cart: rate?.single_cart_price,
                    total: rate?.single_cart_price * quantity,
                    quantity: quantity,
                    type: "SINGLE_CART",
                  },
                ],
              };
            }

            golf_line_obj.carts = cart_obj;

            golf_line_obj.product = {
              id: rate.product_id,
              name: rate.product_name,
              type: rate.product_type_name.en,
              config: rate.product_config,
              custom: false,
            };

            golf_line_obj.hole_options = {
              hole_options: rate.hole_options,
              selected_option: rate.course_hole_combination,
            };

            golf_line_obj.supplier = {
              id: rate.supplier_id,
              name: rate.supplier_name,
              currency: rate.base_currency,
              offerings: rate.supplier_offerings,
            };

            golf_line_obj.green_fee = {
              rate_id: rate.rate_id,
              rate_note: rate?.note || "",
              vat:
                rate.supplier_offerings?.golf?.offerings?.green_fees?.vat || 0,
              withholding_tax:
                rate.supplier_offerings?.golf?.offerings?.green_fees
                  ?.withholding_tax || 0,
              custom_rate: false,
              course_hole_combination:
                rate?.hole_options && rate?.hole_options?.length > 0
                  ? {
                      id: rate.course_hole_combination,
                      holes: rate.hole_options?.find(
                        (hole_option: any) =>
                          hole_option.id === rate.course_hole_combination
                      )?.description,
                    }
                  : null,
              caddie_price: Number(rate.caddie_price),
              product_config: rate.product_config,
              single_cart_enabled: rate.single_cart_enabled,
              single_cart_price: Number(rate.single_cart_price),
              shared_cart_price: Number(rate.shared_cart_price),
              shared_cart_enabled: rate.shared_cart_enabled,
              odd_number_surcharge: Number(rate.odd_number_surcharge),
              max_pax: rate.max_pax,
              currency: rate.base_currency,
              margin:
                rate.rate_id === state?.golf_line?.green_fee.rate_id
                  ? state?.golf_line?.green_fee.margin
                  : rate.margin,
              cost: rate.cost_price,
              price: rate.sell_price,
              surcharge: Number(rate.odd_number_surcharge),
              availability_days: rate.availability_days,
              earliest_time: rate.earliest_time,
              latest_time: rate.latest_time,
              start_date: rate.start_date,
              end_date: rate.end_date,
              caddie_policy: rate.caddie_policy,
              cart_policy: rate.cart_policy,
              booking_window_start_date: rate.booking_window_start_date,
              booking_window_end_date: rate.booking_window_end_date,
            } as GolfOrderLineGreenFee;

            state.golf_line = golf_line_obj;
            state.transfer_line = transfer_line_obj;
          });
        },
      })),
      {
        name: "golfsavers-book-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  );
